import {useQuery} from "@apollo/client";
import {GeoSuggestQuery, GET_GEOSUGGEST} from "@App/graphql/queries/getGeosuggest";
import {Place} from "@App/types";

/**
 * A custom hook that queries geo suggests from the api
 *
 * @param search
 * @param place
 */
export const useGeoSuggest = (search = '', place: Place|null = null) => {
    const {loading, data, error} = useQuery<GeoSuggestQuery>(GET_GEOSUGGEST, {
        variables: {
            search: search,
            placeId: place && (!search || search === place.name)
                ? place.id
                : null,
            lat: place?.lat,
            lon: place?.lon
        },
        skip: ((search.length <= 2) && !place) || !!(place && place.name === search && search.length <= 2)
    })

    return {
        loading,
        error,
        suggests: data ? data.geosuggest : null
    }
}


