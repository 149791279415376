import React, {
    ChangeEvent,
    FocusEvent,
    FunctionComponent,
    KeyboardEvent,
    MouseEvent,
    StrictMode,
    useEffect,
    useState
} from 'react'
import {useDelayedInput} from "../useDelayedInput";
import {useGeoSuggest} from "@App/hooks/graphql/queries/useGeoSuggest";
import {Place} from "@App/types";

export type LocationSearchProps = {
    initialLocation: Place|null,
    placeholder: string
    label?: boolean
    button?: boolean
    className?: string,
    padding?: string
    onSubmit?: (place: Place) => void
}

const LocationFilter: FunctionComponent<LocationSearchProps> = ({
                                                                           initialLocation,
                                                                           placeholder,
                                                                           label,
                                                                           button,
                                                                           className,
                                                                           padding,
                                                                           onSubmit
                                                                       }: LocationSearchProps = {initialLocation: null, className: '', padding: '', placeholder: 'PLZ oder Stadt eingeben', label: false, button: false}) => {

    const [place, setPlace] = useState<Place | null>(initialLocation)
    const [openLocationSuggest, setOpenLocationSuggest] = useState(false)
    const [locationLoading, setLocationLoading] = useState(false)
    const [locationError, setLocationError] = useState('')
    const [search, setSearch] = useState(place ? place.name : '')
    const [delayedSearch, setDelayedSearch] = useState(place ? place.name : '')

    const {loading, error, suggests} = useGeoSuggest(
        place && search === place.name ? search : delayedSearch,
        place ? place : null,
    )

    useDelayedInput([search], () => setDelayedSearch(search), 700)

    useEffect(() => {
        if (suggests && (!place || !suggests.find(suggest => suggest.id === place.id))) {
            setOpenLocationSuggest(true)
        }
    }, [suggests])

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setSearch(e.target.value)
        if (e.target.value.length > 2) {
            setOpenLocationSuggest(true)
        } else {
            setPlace(null)
            setOpenLocationSuggest(false)
        }
    }

    const chooseSuggestion = (suggest: Place) => {
        setPlace(suggest)
        setSearch(suggest.name)
        setDelayedSearch(suggest.name)
        setOpenLocationSuggest(false)
    }

    useEffect(() => {
        if (suggests && suggests.length > 0 && suggests[0].lat) {
            setLocationLoading(false)
            onSubmit && onSubmit(suggests[0])
        }
    }, [suggests])


    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            if (suggests && suggests.length >= 1) {
                chooseSuggestion(suggests[0])
            }
        }
    }

    const close = (e: FocusEvent<HTMLDivElement>) => {
        if (e.currentTarget.contains(e.relatedTarget)) {
            return
        }
        setOpenLocationSuggest(false)
    }

    const open = (e: MouseEvent<HTMLInputElement>): void => {
        e.currentTarget.select()
        setOpenLocationSuggest(true)
    }

    const getLocation = () => {
        setLocationLoading(true)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const lat = position.coords.latitude
                const lon = position.coords.longitude
                setPlace({
                    id: '',
                    name: 'Aktueller Standort',
                    lat,
                    lon,
                    address: {
                        city: ''
                    }
                })
            })
        } else {
            setLocationError("Geolocation wird von diesem Browser nicht unterstützt.")
        }
    }

    return (
        <StrictMode>
            <div tabIndex={0} onBlur={close} className={'w-full pb-4'}>
                <div className="relative">
                    <div className={`${className}`}>
                        <div className={`relative max-w-full`}>
                            <label>
                                {label && <span className={'absolute left-3 text-xs leading-4 top-2 font-medium text-gray-700'}>Ort</span>}
                                <input
                                    value={search}
                                    type="text"
                                    autoComplete={'off'}
                                    placeholder={placeholder}
                                    className={`
                                    w-full
                                    text-gray-500
                                    ${padding}
                                    px-3 pr-8
                                    shadow-sm
                                    rounded-[6px] ${openLocationSuggest && suggests ? "focus:rounded-b-none focus:border-b-neutral-100" : ""}
                                    border border-gray-300 focus:border-primary focus:outline-none
                                    `}
                                    onChange={onChange}
                                    onKeyDown={handleKeyDown}
                                    onClick={open}
                                />
                            </label>
                        </div>
                        <div
                            className={`
                                absolute overflow-auto ${openLocationSuggest && suggests ? "" : "hidden"}
                                w-full max-h-[175px] ${className}
                                bg-white z-[25]
                                rounded rounded-t-none
                                border border-t-0 border-primary
                                text-sm
                                shadow
                                `}>
                            {!loading && suggests && suggests.map((place: Place) => (
                                <div key={place.id} className="px-3 py-2 cursor-pointer font-bold"
                                     onClick={() => chooseSuggestion(place)}>
                                    {place.name}
                                </div>
                            ))}
                        </div>
                        {place !== initialLocation && error && (
                            <div className={'text-warning text-xs mt-2'}>
                                Ein Fehler ist aufgetreten. Ein Administrator wurde informiert.
                            </div>
                        )}
                        {locationError && (
                            <div className={'text-error text-xs mt-2'}>
                                {locationError}
                            </div>
                        )}
                    </div>

                    {button &&
                        <button className={'locateButton relative rounded-md border border-black-200 h-10 w-10 float-right pl-2'}
                                onClick={() => getLocation()}>

                            {!locationLoading && (
                                <i className={'fa-kit fa-location-dot text-md text-primary mr-2 inline-block'}/>
                            )}
                            {locationLoading && (
                                <i className={'fa-kit fa-spinner text-md text-primary mr-2 animate-spin absolute top-3 right-1'}/>
                            )}
                        </button>
                    }
                </div>
            </div>
        </StrictMode>
    )
}

export default LocationFilter
