import {gql} from "@apollo/client";
import {Place} from "@App/types";

export interface GeoSuggestQuery {
    geosuggest: Place[]
}

export const GET_GEOSUGGEST = gql`
    query geosuggest($search: String, $lat:Float, $lon:Float, $placeId: String) {
        geosuggest(search: $search, lat: $lat, lon: $lon, placeId: $placeId) {
            id
            lat
            lon
            name
            address {
                city
            }
        }
    }
`;
