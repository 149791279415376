import {useEffect, useState} from "react";

export const useDelayedInput = (watch: any[], callback: () => void, delay: number) => {

    const [inputTimeout, setInputTimeout] = useState(null)

    useEffect(() => {
        const timer: ReturnType<typeof setTimeout> = setTimeout(() => {
            callback()
        }, delay)
        return () => clearTimeout(timer)
    }, watch)
}


